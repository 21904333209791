@import 'bootstrap/functions';
@import 'bootstrap/variables';

$font-family: 'Open Sans', sans-serif;

$white: #fff !default;
$black: #000 !default;
$red: #df4700;
$orange: #f76f2c;
$yellow: #ffd500 !default;
$green: #5cb85c !default;
$blue: #3ca6d0;
$light-blue: #00a7e1;
$teal: #90a2a9;
$pink: #ff5b77 !default;
$purple: #613d7c !default;

// Create grayscale
$black-light: #030303 !default;
$gray-dark: #292b2c !default;
$gray: #3d474b !default;
$gray-light: #636c72 !default;
$gray-lighter: #e9e9e9;
$gray-lightest: #fafafa;

$table-border-color: #dedfdf;
// Reassign color vars to semantic color scheme
$brand-primary: $orange;
$brand-primary: $orange;
$brand-success: $blue;
$brand-info: $teal !default;
$brand-warning: $orange !default;
$brand-danger: $red !default;
$brand-inverse: $gray-dark !default;

$body-bg: $white;

$link-color: $light-blue;
$link-hover-decoration: none;
$link-hover-color: darken($link-color, 15%);

/*$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
);*/

$font-family-sans-serif: 'Open Sans', 'Segoe UI', Roboto, 'Helvetica Neue',
  Arial, sans-serif;

$dropdown-link-hover-bg: #99aab1;
$dropdown-link-hover-color: #fff;
$dropdown-link-active-bg: #99aab1;

$modal-header-padding: 18px 20px;
$modal-footer-border-color: #dadbdb;
$modal-inner-padding: 18px 22px;
$modal-md: 600px;

$enable-shadows: false;

$theme-colors: (
  'primary': $orange,
  'success': $blue,
);

$input-btn-focus-width: 1px;

// colors new
$white: #ffffff;
$black: #000000;
$orange-5: #fcefe8;
$orange-10: #f9dfd2;
$orange-30: #eb8f61;
$orange-40: #e77c46;
$orange-50: #e25f1d;
$blue-70: #007699;
$blue-60: #00a9dc;
$blue-50: #00c4ff;
$blue-5: #e5f9ff;
$blue_opacity: #00a9dc33;
$grey-95: #19191a;
$grey-90: #19181b;
$grey-80: #323135;
$grey-75: #4b4b4e;
$grey-70: #4b4950;
$grey-60: #f7f7f8;
$grey-55: #7d7d82;
$grey-50: #7d7a85;
$grey-40: #97949e;
$grey-30: #b1b1b4;
$grey-25: #cbcbcd;
$grey-20: #cbcace;
$grey-15: #eaeaeb;
$grey-10: #e5e4e7;
$grey-5: #f2f2f3;
$green-50: #59b146;
$green-10: #def1da;
$green_opacity: #59b14633;
$red-10: #fed2cd;
$red-20: #fea69a;
$red-30: #fd6854;
$red-40: #fd4d35;
$red_opacity: #fd685433;
$purple-50: #7914de;

$thin: 100;
//$light: 300;
//$regular: 400;
$medium: 500;
//$semibold: 600;
$bold: 700;
//$ebold: 800;
//$eebold: 900;
//$italic: italic;
